import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";
import Cards from "../Cards";

export default function Games() {
    return (
        <>
            <div className='hero-container-background'>
                <h1>Our Games</h1>
                <p><i className="fa-solid fa-arrow-down"></i> Give some a go! <i className="fa-solid fa-arrow-down"></i></p>
            </div>
            <Cards />
            <Footer />
        </>);
}