import React from 'react';
import './App.css';
import Navbar from './components/Navbar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Privacy from './components/pages/Privacy';
import About from './components/pages/About';
import Games from './components/pages/Games';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path = '/' exact element={<Home/>}/>
        <Route path = '/games' exact element={<Games/>}/>
        <Route path = '/about' exact element={<About/>}/>
        <Route path = '/privacy' exact element={<Privacy/>}/>
      </Routes>
    </Router>
      </>
  );
}

export default App;
