import React from 'react';
import { Link } from 'react-router-dom';
// import { Button } from './Button';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container' id='footer'>
        {/* <section className='footer-subscription' >
            <p className='footer-subscription-heading'>
                some stuff here.
            </p>
            <p className='footer-subscription-text'>
                description text.
            </p>
            <div className='input-areas'>
                <form>
                    <input className='footer-input' type='email' name='email' placeholder='your email'/>
                    <Button buttonStyle='btn--outline'>Sub Yo</Button>
                </form>
            </div>
        </section> */}
        <div className='footer-links'>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>About Us</h2>
                    <Link to='/about'>About</Link>
                    {/* <Link to='/games'>Games</Link> */}
                    {/* <Link to='/privacy'>Privacy</Link> */}
                </div>
                <div className='footer-link-items'>
                    <h2>Games</h2>
                    <Link to='https://nothappygamedev.com/GrabtheGold/index.html' target='_blank'>Grab the Gold</Link>
                    {/* <Link to='https://play.google.com/store/apps/details?id=com.coltari.grabthegold' target='_blank' >Grab the Gold Android</Link> */}
                    <Link to='https://nothappygamedev.com/BH/index.html' target='_blank'>Bullet Heaven</Link>
                    <Link to='https://nothappygamedev.itch.io/' target='_blank'>Others on Itch.io</Link>
                </div>
            </div>
            <div className='footer-link-wrapper'>
                <div className='footer-link-items'>
                    <h2>Social Media</h2>
                    {/* <Link to='https://www.reddit.com/r/Fomgnore/' target='_blank' >Reddit</Link> */}
                    <Link to='https://www.instagram.com/nothappygamedev/' target='_blank' >Instagram</Link>
                    <Link to='https://mastodon.gamedev.place/@Coltari' target='_blank' >Mastodon</Link>
                    <Link to='https://www.youtube.com/@NotHappyGameDev-ft4dj' target='_blank' >YouTube</Link>
                </div>
                {/* <div className='footer-link-items'>
                    <h2>Social Media</h2>
                    <Link to='/about'>Reddit</Link>
                    <Link to='/games'>Instagram</Link>
                    <Link to='/privacy'>Privacy</Link>
                </div> */}
            </div>
        </div>
        <section className='social-media'>
            <div className='social-media-wrap'>
                <small className='website-rights'>Not Happy Game Dev © 2024</small>
                <div className='footer-logo'>
                    <Link className='social-logo' to='/'>
                        <img src='/images/VerticalLogoSmall.png' alt='Not Happy'/>
                    </Link>
                </div>
                <div className='social-icons'>
                    {/* <Link className='social-icon-link facebook' to='/' target='_blank' aria-label='Facebook'>
                        <i className='fab fa-facebook-f'></i>
                    </Link> */}
                    {/* <Link className='social-icon-link reddit' to='https://www.reddit.com/r/Fomgnore/' target='_blank' aria-label='Reddit'>
                        <i className='fab fa-reddit'></i>
                    </Link> */}
                    <Link className='social-icon-link instagram' to='https://www.instagram.com/nothappygamedev/' target='_blank' aria-label='Instagram'>
                        <i className='fab fa-instagram'></i>
                    </Link>
                    <Link className='social-icon-link youtube' to='https://www.youtube.com/@NotHappyGameDev-ft4dj' target='_blank' aria-label='YouTube'>
                        <i className='fab fa-youtube'></i>
                    </Link>
                    <Link className='social-icon-link mastodon' to='https://mastodon.gamedev.place/@Coltari' target='_blank' aria-label='Mastodon'>
                        <i className='fab fa-mastodon'></i>
                    </Link>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Footer