import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <div className='cards__container'>
            <h1>Game Jam Entries</h1>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src="https://img.itch.zone/aW1nLzE2ODU4MzkxLnBuZw==/315x250%23c/SmDVjb.png" text="Forage In The Forest" label="Snows Souls-Like Game Jam! - Summer 2024" path='https://nothappygamedev.itch.io/forage-in-the-forest' target='_blank'/>
                    <CardItem src="https://img.itch.zone/aW1nLzE2MjE5OTE5LnBuZw==/315x250%23c/1y21T6.png" text="Run Rabbit" label="Bullet Hell Jam 5" path='https://nothappygamedev.itch.io/run-rabbit' target='_blank'/>
                </ul>
            </div>
            <h1>Javascript based Browser Games</h1>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src="images/title.png" text='Bullet Heaven: Road to Hell' label='Fast Paced' path='https://nothappygamedev.com/BH/index.html' target='_blank'/>
                    <CardItem src='images/gtgjs.png' text='Grab the Gold V1' label='Early Work' path='https://nothappygamedev.com/GrabtheGold/index.html' target='_blank'/>
                </ul>
            </div>
            <h1>Find more on Itch.io</h1>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src='https://static.itch.io/images/itchio-textless-black.svg' text='Itch.io' label='More Games' path='https://nothappygamedev.itch.io/'  target='_blank'/>
                </ul>
            </div>
            {/* <h1>Check Out These on the Play Store</h1>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem src='images/gtgmob.png' text='Grab the Gold Mobile' label='Android Version!' path='https://play.google.com/store/apps/details?id=com.coltari.grabthegold'  target='_blank'/>
                </ul>
            </div> */}
        </div>
    </div>
  )
}

export default Cards