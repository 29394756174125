import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";

export default function Privacy() {
    return (
    <>
        <div className="hero-container-background">
            <h1>Privacy Policy</h1>
            <p>Our Apps collect no personal, device,</p>
            <p> network or usage data.</p>
            <p>Or any other sorts of data for that matter!</p>
            <p>Just have fun!</p>
        </div>
    <Footer />
    </>);
}