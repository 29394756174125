import React from "react";
import '../../App.css';
import '../HeroSection.css';
import Footer from "../Footer";

export default function About() {
    return (
    <>
        <div className='hero-container-background'>
            <h1>About</h1>
            <p>Way back in the early days of 2022, somewhere in the north of England,</p>
            <p> there was a developer.</p>
            <p>He was bored of doing the same old stuff day in day out.</p>
            <p>One day he decided to make a simple game,</p>
            <p>friends enjoyed playing it and the developer enjoyed making it.</p>
            <p>So... he continued making games (in his spare time).</p>
            <p>but he's never really happy with the outcome.</p>
            <p>Thus Not Happy Game Dev was born.</p>
        </div>
        <Footer />
    </>);
}