import React from 'react';
import '../App.css';
import { Button } from './Button.js';
import { AnchorButton } from './AnchorButton.js';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
        <video autoPlay loop muted src="/video/bhbosssnip.mp4" />
        {/* <iframe src="https://www.youtube.com/embed/Bdkt7486wSQ?controls=0&autoplay=1&mute=1&loop=1&playlist=Bdkt7486wSQ" title="video" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope" allowFullScreen></iframe> */}
        <h1>Welcome!</h1>
        <p>Have a look at what we do</p>
        <div className='hero-btns'>
            <AnchorButton className='btns' buttonStyle='btn--outline' buttonSize='btn--large' path='/#footer'>
              Get in Contact
            </AnchorButton>
            <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' path='/games'>
                Play Something! <i className='far fa-play-circle'/>
            </Button>
        </div>
    </div>
  )
}

export default HeroSection